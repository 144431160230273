export const showValidationErrors = (payload) => {
    return {
        type: 'validation/show',
        payload
    }
}

export const hideErrors = () => {
    return {
        type: 'errors/clear',
    }
}

export const showLoader = () => {
    return {
        type: 'loader/show'
    }
}

export const hideLoader = () => {
    return {
        type: 'loader/hide'
    }
}

export const redirectTo = (payload) => {
    return {
        type: 'redirect/to',
        payload: payload
    }
}

export const clearRedirect = () => {
    return {
        type: 'redirect/ed'
    }
}

export const setCurrentUser = (payload) => {
    return {
        type: 'user/current',
        payload
    }
}

export const clearUser = () => {
    return {
        type: 'user/clear'
    }
}

export const setError = (payload) => {
    return {
        type: 'api_error/set',
        payload
    }
}