import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const Analytics = loadable(() => import("../pages/Dashboard/Analytics"));

const DashboardRoutes = [
    {
        path: "/analytics",
        page: true,
        element: (
            <Protected permission="view-dashboard">
                <Analytics />
            </Protected>
        ),
    },
];

export default DashboardRoutes;
