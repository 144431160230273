import loadable from "@loadable/component"
import Protected from "../pages/Protected"

const UsersList = loadable(() => import('../pages/Users/List'))
const UserCreate = loadable(() => import('../pages/Users/Form'))
const UserEdit = loadable(() => import('../pages/Users/Form'))
const UserDetails = loadable(() => import('../pages/Users/Details'))
const UserFollowers = loadable(() => import('../pages/Users/Followers'))
const UserFollowing = loadable(() => import('../pages/Users/Following'))

const UserRoutes = [
    { path: '/users', element: <Protected permission="view-users" page={true}><UsersList /></Protected> },
    { path: '/user/:id', element: <Protected permission="view-user" page={true}><UserDetails /></Protected> },
    { path: '/user/create', element: <Protected permission="create-user" page={true}><UserCreate /></Protected> },
    { path: '/user/:id/edit', element: <Protected permission="edit-user" page={true}><UserEdit /></Protected> },
    { path: '/user/:id/following', element: <Protected permission="view-user" page={true}><UserFollowing /></Protected> },
    { path: '/user/:id/followers', element: <Protected permission="view-user" page={true}><UserFollowers /></Protected> },
]

export default UserRoutes