import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const AdminMessages = loadable(() => import("../pages/AdminMessages/List"));
const SupportList = loadable(() => import("../pages/SupportChats/List"));
// const TransactionDetails = loadable(() => import("../pages/Transactions/Details"));

const SupportRoutes = [
    {
        path: "/support",
        element: (
            <Protected permission="view-support" page={true}>
                <SupportList />
            </Protected>
        ),
    },
    {
        path: "/support/my-messages",
        element: (
            <Protected permission="my-messages" page={true}>
                <AdminMessages />
            </Protected>
        ),
    },
    // {
    //     path: "/transaction/:id",
    //     element: (
    //         <Protected permission="view-transaction" page={true}>
    //             {/* <TransactionDetails /> */}
    //         </Protected>
    //     ),
    // },
];

export default SupportRoutes;
