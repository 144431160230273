import axios from "axios";

const api = (request = {}, method = 'GET', headers = {}, onUploadProgress = null) => {
    const params = {
        url: request.url,
        method: method,
        responseType: 'json',
        headers
    }

    
    if (method === 'GET')
        params.params = request.data || {}
    else
        params.data = request.data || {}
    
    if (!!onUploadProgress) {
        params.onUploadProgress = onUploadProgress
    }

    return axios(params)
}

export default api