import api from "../core/api"
import { LogError } from "../core/log"
import env from '../env'
import { hideLoader, hideErrors, redirectTo, showLoader, showValidationErrors, setError } from "../redux/actions/app"

const reverie_api = (url, data = {}, method = 'GET', onUploadProgress = null) => async dispatch => {
    try {
        dispatch(hideErrors())
        dispatch(showLoader())

        const response = await api({
            url: `${env.api_url}/${url}`,
            data: data
        }, method, request_headers(method == 'GET'), onUploadProgress)

        dispatch(hideLoader())

        return response.data
    } catch ({ response }) {
        dispatch(hideLoader())
        if (url !== 'log-error') {
            handle_exception(response, dispatch)
        }
    }
}

const request_headers = (not_form) => {
    const headers = {
        "Content-Type": not_form ? "application/json" : "multipart/form-data",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Max-Age': 600
    }

    const token =  localStorage.getItem('token')

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    return headers
}

export default reverie_api

const handle_exception = async (error = { status: 500 }, dispatch = null) => {
    try {
        await dispatch(LogError(error))
    } catch (error) {
        console.log(error)
    }

    switch (error.status) {
        case 401:
            dispatch(redirectTo('/login'))
            break;

        case 403:
            dispatch(redirectTo('/403'))
            break;

        case 422:
            dispatch(showValidationErrors(error.data.data.message))
            break;

        case 404:
            dispatch(redirectTo('/404'))
            break;
        
        default:
            if (error.data)
                dispatch(setError(error.data.message))
            else
                dispatch(redirectTo('/500'))
            break;
    }
}