import { Link } from "react-router-dom"

const E404 = () => {
    const view = () => {
        return (
            <section className="section">
                <div className="container mt-5">
                    <div className="page-error">
                        <div className="page-inner">
                            <h1>404</h1>
                            <div className="page-description">
                                The page you were looking for could not be found.
                            </div>
                            <div className="mt-3">
                                <Link to="/">Back to Home</Link>
                            </div>
                        </div>
                    </div>
                    <div className="simple-footer mt-5">Copyright &copy; CodiePie 2020</div>
                </div>
            </section>
        )
    }

    return view()
}

export default E404