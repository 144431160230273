import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { redirectTo } from "../redux/actions/app"

const Protected = props => {
    const dispatch = useDispatch()
    const user = useSelector(store => store.app.user)
    const { permission, page } = props
    
    useEffect(() => {
        if (page == true && !user?.permissions?.includes(permission) && user.id) {
            dispatch(redirectTo('/403'))
        }
    }, [dispatch, permission, redirectTo, user.id])

    return (
        <>
            {user?.permissions?.includes(permission) && props.children}
        </>
    )
}

export default Protected