import { Route, Routes } from "react-router"
import E403 from "../pages/Errors/E403"
import E404 from "../pages/Errors/E404"
import E500 from "../pages/Errors/E500"

const ErrorRoutes = [
    { path: '/403', element: <E403 /> },
    { path: '/404', element: <E404 /> },
    { path: '/500', element: <E500 /> }
]

export default ErrorRoutes