import loadable from "@loadable/component"
import Protected from "../pages/Protected"

const StripeForm = loadable(() => import('../pages/Configurations/Stripe'))
const SystemDetails = loadable(() => import('../pages/Configurations/SystemDetails'))

const ConfigurationRoutes = [
    { path: '/configurations/stripe', element: <Protected page={true} permission="edit-system-configurations"><StripeForm /></Protected> },
    { path: '/configurations/system', element: <Protected page={true} permission="edit-system-configurations"><SystemDetails /></Protected> },
]

export default ConfigurationRoutes