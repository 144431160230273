import loadable from "@loadable/component"
import Protected from "../pages/Protected"

const RolesList = loadable(() => import('../pages/Roles/List'))
const RoleCreate = loadable(() => import('../pages/Roles/Form'))
const RoleEdit = loadable(() => import('../pages/Roles/Form'))
const RoleDetails = loadable(() => import('../pages/Roles/Details'))

const RoleRoutes = [
    { path: '/roles', element: <Protected page={true} permission="view-roles"><RolesList /></Protected> },
    { path: '/role/create', element: <Protected page={true} permission="create-role"><RoleCreate /></Protected> },
    { path: '/role/:id/edit', element: <Protected page={true} permission="edit-role"><RoleEdit /></Protected> },
    { path: '/role/:id', element: <Protected page={true} permission="view-role"><RoleDetails /></Protected> }
]

export default RoleRoutes