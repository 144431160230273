const initialState = {
    user: {},
    form_errors: {},
    show_loader: false,
    api_error: '',
    roles: []
}

const app = (state = initialState, action) => {
    switch (action.type) {
        case 'validation/show':
            return {...state, form_errors: action.payload}

        case 'errors/clear':
            return {...state, form_errors: {}, api_error: '' }
    
        case 'loader/show':
            return {...state, show_loader: true}
        
        case 'loader/hide':
            return {...state, show_loader: false}
            
        case 'redirect/to':
            return {...state, redirect: action.payload, redirected: true}

        case 'redirect/ed':
            return {...state, redirect: '', redirected: false}

        case 'user/current':
            return {...state, user: action.payload}
        
        case 'user/clear':
            return {...state, user: {}}
        
        case 'api_error/set':
            return {...state, api_error: action.payload }

        default:
            return state
    }
}

export default app