import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const JobsList = loadable(() => import("../pages/Jobs/List"));
const JobCreate = loadable(() => import("../pages/Jobs/Form"));
const JobEdit = loadable(() => import("../pages/Jobs/Form"));
const JobDetails = loadable(() => import("../pages/Jobs/Details"));
const JobApplicants = loadable(() => import("../pages/Jobs/Applicants"));
const Resume = loadable(() => import("../pages/Users/Resume"));

const JobRoutes = [
    {
        path: "/jobs",
        element: (
            <Protected page={true} permission="view-jobs">
                <JobsList />
            </Protected>
        ),
    },
    {
        path: "/job/create",
        element: (
            <Protected page={true} permission="create-job">
                <JobCreate />
            </Protected>
        ),
    },
    {
        path: "/job/:id",
        element: (
            <Protected page={true} permission="view-job">
                <JobDetails />
            </Protected>
        ),
    },
    {
        path: "/job/:id/edit",
        element: (
            <Protected page={true} permission="edit-job">
                <JobEdit />
            </Protected>
        ),
    },
    {
        path: "/job/:id/applicants",
        element: (
            <Protected page={true} permission="view-job">
                <JobApplicants />
            </Protected>
        ),
    },
    {
        path: "/job/:job/applicant/:applicant",
        element: (
            <Protected page={true} permission="view-job">
                <Resume />
            </Protected>
        ),
    },
];

export default JobRoutes;
