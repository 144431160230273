import { useEffect } from "react"
import { useSelector } from "react-redux"

const PageLoader = () => {
    const show = useSelector(store => store.app.show_loader)

    return (
        <>
            {show && <div className="page-loader-wrapper" style={{opacity: '0.5'}}>
                <span className="loader"><span className="loader-inner"></span></span>
            </div>}
        </>
    )
}

export default PageLoader