import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const TransactionsList = loadable(() => import("../pages/Transactions/List"));
const TransactionDetails = loadable(() => import("../pages/Transactions/Details"));

const TransactionRoutes = [
    {
        path: "/transactions",
        element: (
            <Protected permission="view-transactions" page={true}>
                <TransactionsList />
            </Protected>
        ),
    },
    {
        path: "/transaction/:id",
        element: (
            <Protected permission="view-transaction" page={true}>
                <TransactionDetails />
            </Protected>
        ),
    },
];

export default TransactionRoutes;
