import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const ChallengesList = loadable(() => import('../pages/Challenges/List'))
const ChallengeCreate = loadable(() => import('../pages/Challenges/Form'))
const ChallengeEdit = loadable(() => import('../pages/Challenges/Form'))
const ChallengeDetails = loadable(() => import('../pages/Challenges/Details'))
const ChallengeParticipants = loadable(() => import('../pages/Challenges/Participants'))

const ChallengeRoutes = [
    { path: '/challenges', element: <Protected page={true} permission="view-challenges"><ChallengesList /></Protected> },
    { path: '/challenge/create', element: <Protected page={true} permission="create-challenge"><ChallengeCreate /></Protected> },
    { path: '/challenge/:id', element: <Protected page={true} permission="view-challenge"><ChallengeDetails /></Protected> },
    { path: '/challenge/:id/edit', element: <Protected page={true} permission="edit-challenge"><ChallengeEdit /></Protected> },
    { path: '/challenge/:id/participants', element: <Protected page={true} permission="view-challenge"><ChallengeParticipants /></Protected> },
]

export default ChallengeRoutes