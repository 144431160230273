import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const TemplatesList = loadable(() => import('../pages/NotificationTemplates/List'))
const TemplateForm = loadable(() => import('../pages/NotificationTemplates/Form'))
const TemplateDetails = loadable(() => import('../pages/NotificationTemplates/Details'))

const NotificationTemplateRoutes = [
    { path: '/notification-templates', element: <Protected page={true} permission="view-notification-templates"><TemplatesList /></Protected> },
    { path: '/notification-template/:id/edit', element: <Protected page={true} permission="edit-notification-templates"><TemplateForm /></Protected> },
    { path: '/notification-template/:id', element: <Protected page={true} permission="view-notification-templates"><TemplateDetails /></Protected> }
]

export default NotificationTemplateRoutes