import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const List = loadable(() => import('../pages/Posts/List'))
const PostDetails = loadable(() => import('../pages/Posts/Details'))
const CreateForm = loadable(() => import('../pages/Posts/Form'))
const EditForm = loadable(() => import('../pages/Posts/Form'))
const UnsafePosts = loadable(() => import('../pages/Posts/Unsafe'))
const ReviewPost = loadable(() => import('../pages/Posts/Review'))
const PostComments = loadable(() => import('../pages/Posts/Comments'))
const PostLikes = loadable(() => import('../pages/Posts/Likes'))
const PostViews = loadable(() => import('../pages/Posts/Views'))

const PostRoutes = [
    { path: '/posts', element: <Protected page={true} permission="view-posts"><List /></Protected> },
    { path: '/post/create', element: <Protected page={true} permission="create-post"><CreateForm /></Protected> },
    { path: '/post/:id/edit', element: <Protected page={true} permission="edit-post"><EditForm /></Protected> },
    { path: '/post/:id', element: <Protected page={true} permission="view-posts"><PostDetails /></Protected> },
    { path: '/posts/unsafe', element: <Protected page={true} permission="moderate-posts"><UnsafePosts /></Protected> },
    { path: '/posts/:id/review', element: <Protected page={true} permission="moderate-posts"><ReviewPost /></Protected> },
    { path: '/post/:id/comments', element: <Protected page={true} permission="view-post"><PostComments /></Protected> },
    { path: '/post/:id/likes', element: <Protected page={true} permission="view-post"><PostLikes /></Protected> },
    { path: '/post/:id/views', element: <Protected page={true} permission="view-post"><PostViews /></Protected> },
]

export default PostRoutes