import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const ClubDetails = loadable(() => import('../pages/Clubs/Details'))
const ClubsList = loadable(() => import('../pages/Clubs/List'))
const ClubCreate = loadable(() => import('../pages/Clubs/Form'))
const ClubEdit = loadable(() => import('../pages/Clubs/Form'))
const ClubMembers = loadable(() => import('../pages/Clubs/Members'))
const ClubPosts = loadable(() => import('../pages/Clubs/Posts'))

const ClubRoutes = [
    { path: '/club/create', element: <Protected page={true} permission="create-club"><ClubCreate /></Protected> },
    { path: '/clubs', element: <Protected page={true} permission="view-clubs"><ClubsList /></Protected> },
    { path: '/club/:id', element: <Protected page={true} permission="view-club"><ClubDetails /></Protected> },
    { path: '/club/:id/edit', element: <Protected page={true} permission="edit-club"><ClubEdit /></Protected> },
    { path: '/club/:id/members', element: <Protected permission="view-club"><ClubMembers /></Protected>},
    { path: '/club/:id/posts', element: <Protected permission="view-club"><ClubPosts /></Protected>},
]

export default ClubRoutes