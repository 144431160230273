import loadable from "@loadable/component"
import Protected from "../pages/Protected"

const GendersLookup = loadable(() => import('../pages/Lookups/Genders'))
const InterestsLookup = loadable(() => import('../pages/Lookups/Interests'))
const JobTypesLookup = loadable(() => import('../pages/Lookups/JobTypes'))
const PayTypesLookup = loadable(() => import('../pages/Lookups/PayTypes'))
const ReligionsLookup = loadable(() => import('../pages/Lookups/Religions'))

const LookupRoutes = [
    { path: '/genders', element: <Protected page={true} permission="view-lookups"><GendersLookup /></Protected> },
    { path: '/interests', element: <Protected page={true} permission="view-lookups"><InterestsLookup /></Protected> },
    { path: '/job-types', element: <Protected page={true} permission="view-lookups"><JobTypesLookup /></Protected> },
    { path: '/pay-types', element: <Protected page={true} permission="view-lookups"><PayTypesLookup /></Protected> },
    // { path: '/religions', element: <Protected page={true} permission="view-lookups"><ReligionsLookup /></Protected> }
]

export default LookupRoutes