import { Route, Routes, useNavigate } from "react-router";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fontawesome/css/all.min.css";
import "./assets/css/style.min.css";
import "./assets/css/components.min.css";
import ErrorRoutes from "./routes/errors";
import PageRoutes from "./routes/pages";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { clearRedirect, redirectTo, setCurrentUser } from "./redux/actions/app";
import reverie_api from "./helpers/reverie_api";
import PostRoutes from "./routes/posts";
import E404 from "./pages/Errors/E404";
import PermissionRoutes from "./routes/permissions";
import RoleRoutes from "./routes/roles";
import UserRoutes from "./routes/users";
import ClubRoutes from "./routes/clubs";
import JobRoutes from "./routes/jobs";
import ChallengeRoutes from "./routes/challenges";
import LookupRoutes from "./routes/lookups";
import NotificationTemplateRoutes from "./routes/notification_templates";
import SubscriptionRoutes from "./routes/subscription_rules";
import ConfigurationRoutes from "./routes/configurations";
import DashboardRoutes from "./routes/dashboard";
import ReportRoutes from "./routes/reports";
import TransactionRoutes from "./routes/transactions";
import SupportRoutes from "./routes/support";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirect_path = useSelector((store) => store.app.redirect);
  const redirected = useSelector((store) => store.app.redirected);
  const user = useSelector((store) => store.app.user);

  const routes = []
    .concat(PageRoutes)
    .concat(ErrorRoutes)
    .concat(PostRoutes)
    .concat(PermissionRoutes)
    .concat(RoleRoutes)
    .concat(UserRoutes)
    .concat(ClubRoutes)
    .concat(JobRoutes)
    .concat(ChallengeRoutes)
    .concat(LookupRoutes)
    .concat(NotificationTemplateRoutes)
    .concat(SubscriptionRoutes)
    .concat(ConfigurationRoutes)
    .concat(DashboardRoutes)
    .concat(ReportRoutes)
    .concat(TransactionRoutes)
    .concat(SupportRoutes)
    .concat([{ path: "*", element: <E404 /> }]);

  useEffect(() => {
    if (redirected === true) {
      dispatch(clearRedirect());

      navigate(redirect_path);
    }
  }, [dispatch, redirected, navigate, redirect_path]);

  const loginUser = useCallback(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(reverie_api("users/current")).then((response = {}) => {
        if (response.success) {
          dispatch(setCurrentUser(response.data));
        }
      });
    } else {
      dispatch(redirectTo("/login"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user.id) {
      loginUser();
    }
  }, [dispatch, loginUser, user.id]);

  const renderRoutes = useMemo(() => {
    return routes.map((r, i) => (
      <Route key={i} path={r.path} element={r.element} />
    ));
  }, [routes]);

  return (
    <>
      <Routes>{renderRoutes}</Routes>
    </>
  );
};

export default App;
