import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const SubscriptionRulesList = loadable(() => import('../pages/SubscriptionRules/List'))
const SubscriptionRuleForm = loadable(() => import('../pages/SubscriptionRules/Form'))

const SubscriptionRoutes = [
    { path: '/subscription-rules', element: <Protected page={true} permission="modify-subscription-rules"><SubscriptionRulesList /></Protected> },
    { path: '/subscription-rules/:id/edit', element: <Protected page={true} permission="modify-subscription-rules"><SubscriptionRuleForm /></Protected> }
]

export default SubscriptionRoutes;