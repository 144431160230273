import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const Dashboard = loadable(() => import("../pages/Dashboard/Analytics"));
const Login = loadable(() => import("../pages/Login"));

const PageRoutes = [
    {
        path: "/",
        element: (
            <Protected permission="view-dashboard" page={true}>
                <Dashboard />
            </Protected>
        ),
    },
    { path: "/login", element: <Login /> },
];

export default PageRoutes;
