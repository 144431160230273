import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const PermissionsList = loadable(() => import('../pages/Permissions/List'))
const PermissionForm = loadable(() => import('../pages/Permissions/Form'))

const PermissionRoutes = [
    { path: '/permissions', element: <Protected page={true} permission="view-permissions"><PermissionsList /></Protected> },
    { path: '/permission/create', element: <Protected page={true} permission="create-permission"><PermissionForm /></Protected> },
    { path: '/permission/:id/edit', element: <Protected page={true} permission="edit-permission"><PermissionForm /></Protected>}
]

export default PermissionRoutes