import loadable from "@loadable/component";
import Protected from "../pages/Protected";

const NewUsers = loadable(() => import("../pages/Reports/NewUsers"));
const NewClubs = loadable(() => import("../pages/Reports/NewClubs"));
const NewChallenges = loadable(() => import("../pages/Reports/NewChallenges"));
const NewJobs = loadable(() => import("../pages/Reports/NewJobs"));
const NewPosts = loadable(() => import("../pages/Reports/NewPosts"));
const NewLikes = loadable(() => import("../pages/Reports/NewLikes"));
const NewComments = loadable(() => import("../pages/Reports/NewComments"));
const NewShares = loadable(() => import("../pages/Reports/NewShares"));

const ReportRoutes = [
    {
        path: "/reports/new-users",
        element: (
            <Protected page={true} permission="view-reports">
                <NewUsers />
            </Protected>
        ),
    },

    {
        path: "/reports/new-clubs",
        element: (
            <Protected page={true} permission="view-reports">
                <NewClubs />
            </Protected>
        ),
    },

    {
        path: "/reports/new-challenges",
        element: (
            <Protected page={true} permission="view-reports">
                <NewChallenges />
            </Protected>
        ),
    },
    {
        path: "/reports/new-jobs",
        element: (
            <Protected page={true} permission="view-reports">
                <NewJobs />
            </Protected>
        ),
    },
    {
        path: "/reports/new-posts",
        element: (
            <Protected page={true} permission="view-reports">
                <NewPosts />
            </Protected>
        ),
    },

    {
        path: "/reports/new-likes",
        element: (
            <Protected page={true} permission="view-reports">
                <NewLikes />
            </Protected>
        ),
    },
    {
        path: "/reports/new-comments",
        element: (
            <Protected page={true} permission="view-reports">
                <NewComments />
            </Protected>
        ),
    },
    {
        path: "/reports/new-shares",
        element: (
            <Protected page={true} permission="view-reports">
                <NewShares />
            </Protected>
        ),
    },
];

export default ReportRoutes;
